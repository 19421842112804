module.exports = {
  comm: {
    home: 'خانه',
    pricing: 'قیمت‌گذاری',
    blog: 'کلونینگ صدا و API',
    language: 'زبان:',
    select_language: 'زبان را انتخاب کنید',
    txt2voice: 'تبدیل متن به گفتار',
    voice2txt: 'تبدیل گفتار به متن',
    voiceclone: 'کلونینگ صدا',
    video2txt: 'تبدیل ویدیو به متن',
    footer_help: 'نیاز به کمک دارید؟ به ما ایمیل بزنید:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'ورود',
    login_desc: 'برای دسترسی به حساب تولید کننده صدای TikTok خود وارد شوید',
    logout: 'خروج',
  },
  txt2voice: {
    title: 'تولید کننده صدای TikTok: تولید صداهای AI خنده دار TikTok',
    description: 'تولید و دانلود صداهای AI خنده دار TikTok به صورت رایگان مانند صدای jessie، صدای C3PO، صدای ghostface...',
    keywords: 'تولید کننده صدای TikTok، صدای AI TikTok، تولید کننده صدای Tik Tok',
    main_title_p01: 'تولید کننده صدای TikTok',
    main_title_p02: 'تولید صداهای خنده دار TikTok: صدای jessie، صدای C3PO، صدای ghostface',
    main_textarea_holder: 'متن را اینجا تایپ یا جایگذاری کنید',
    main_genvoice: 'تولید',
    main_generating: 'در حال تولید',
    main_input_empty: 'لطفا متن را تایپ یا جایگذاری کنید',
    daily_usage_limit_msg: '۱) حد استفاده روزانه به حداکثر رسیده است. لطفاً فردا برگردید.۲) اگر فوراً به استفاده بیشتری نیاز دارید، لطفاً به ما ایمیل بزنید.',
    text_max_prompt1: 'لطفا تعداد کلمات را به',
    text_max_prompt2: 'کلمه یا کمتر محدود کنید!',
    popup_nologin_title: 'طرح اشتراک خود را ارتقا دهید',
    popup_nologin_desc: 'با ارتقا به اشتراک ویژه، زمان بیشتری برای تولید صدا باز کنید.',
    popup_nologin_btntext: 'طرح‌های اشتراک را مشاهده کنید',
    popup_nosub_title: 'طرح اشتراک خود را ارتقا دهید',
    popup_nosub_desc: 'با ارتقا به اشتراک ویژه، زمان بیشتری برای تولید صدا باز کنید.',
    popup_nosub_btntext: 'طرح‌های اشتراک را مشاهده کنید',
    popup_sublimit_title: 'اشتراک تمام شده است، لطفاً ارتقا دهید',
    popup_sublimit_desc: 'تجربه خود را با ویژگی‌های برتر و دسترسی نامحدود ما بهبود بخشید.',
    popup_sublimit_btntext: 'برای خرید بیشتر بروید',
  },
  pricing: {
    new_features_alert: "📣 ما خدمات 🎙️ کلونینگ صدا و 🤖 API را برای اعضای پولی راه‌اندازی کرده‌ایم! (پس از پرداخت، برای کمک در مورد این ویژگی‌ها به tiktokaivoicetool@gmail.com ایمیل بزنید). علاوه بر این، برای هرگونه درخواست سفارشی‌سازی، لطفاً با ما تماس بگیرید😃",
    pricing_title: 'از سازندگان فردی تا شرکت‌های بزرگ، طرحی مناسب شما داریم.',
    pricing_desc: 'بیش از 100 صدای طبیعی و انسانی. بیش از 15 زبان. کمترین قیمت برای تقریباً همه',
    sub_free_name: 'رایگان',
    sub_free_desc: 'برای افرادی که می‌خواهند جدیدترین صدای هوش مصنوعی را امتحان کنند',
    sub_free_content: [
      'محدودیت 1000 کاراکتر در ماه',
      '5 تولید در روز',
    ],
    sub_starter_name: 'مبتدی',
    sub_starter_desc: 'برای سازندگانی که محتوای برتر برای مخاطبان جهانی تولید می‌کنند',
    sub_starter_content: [
      '1 کلون صدا',
      'محدودیت 300,000 کاراکتر در ماه (حدود 7 ساعت صدا)',
      'دانلود نامحدود',
      'پشتیبانی ایمیلی در 72 ساعت',
      'دسترسی زودهنگام به صداها و ویژگی‌های جدید',
    ],
    sub_pro_name: 'حرفه‌ای',
    sub_pro_desc: 'برای سازندگانی که تولید محتوای خود را گسترش می‌دهند',
    sub_pro_content: [
      'دسترسی API',
      '3 کلون صدا',
      'محدودیت 1,000,000 کاراکتر در ماه (حدود 24 ساعت صدا)',
      'دانلود نامحدود',
      'پشتیبانی ایمیلی در 48 ساعت',
      'دسترسی زودهنگام به صداها و ویژگی‌های جدید',
    ],
    period_year: 'سالانه',
    period_month: 'ماهانه',
    period_month_short: 'ماه',
    billed_year: 'صورتحساب سالانه',
    most_popular: 'محبوب‌ترین',
    discount_quantity: '48 دلار صرفه‌جویی کنید',
    buy_btntext: 'شروع کنید',
    pay_succ: 'پرداخت موفق!',
    pay_succ_desc: 'از خرید شما متشکریم. تراکنش با موفقیت پردازش شد.',
    pay_succ_btntext: 'شروع استفاده از صدای TikTok',
    pay_fail: 'پرداخت ناموفق!',
    pay_fail_desc: "متأسفیم، ما نتوانستیم تراکنش شما را پردازش کنیم. لطفاً دوباره تلاش کنید یا با تیم پشتیبانی ما تماس بگیرید (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'بازگشت به صفحه اصلی',
  },
  setting: {
    setting: 'تنظیمات',
    setting_title: 'حساب، استفاده و اشتراک خود را در اینجا مدیریت کنید.',
    basic_info: 'اطلاعات اولیه',
    user_name: 'نام',
    user_email: 'ایمیل',
    user_account: 'حساب',
    user_subscript: 'طرح فعلی',
    user_usage: 'استفاده',
    manage_subscript: 'مدیریت اشتراک',
    use_limit_promote: "شما 5000 کاراکتر از محدودیت 10000 کاراکتر ماهانه خود را استفاده کرده‌اید.",
    not_logged_in: 'شما وارد نشده‌اید',
    go_to_login: 'برای ورود بروید >>',
  },
  login: {
    title: "تولید کننده صدای TikTok",
    subtitle: "برای دسترسی به حساب تولید کننده صدای TikTok خود وارد شوید",
    alreadyLoggedIn: "شما قبلاً وارد شده‌اید",
    goToHome: "رفتن به صفحه اصلی"
  },
  faq: {
    quest1: 'تولید کننده صدای TikTok - بهترین ابزار صدای AI TikTok',
    answer1_1: 'این یک ابزار تولید صدای AI tiktok است که با جدیدترین فناوری tts tiktok توسعه یافته است و می‌تواند صداهای tiktok مانند صدای jessie (صدای زن)، صدای siri، صدای ghostface، صدای C3PO (صدای ربات)، صدای عمیق (داستان‌گو)، صدای skye، صدای گرم، صدای bestie، صدای قهرمان (صدای chiris)، صدای همدلی، صدای جدی، صدای joey، صدای stitch، صدای Stormtrooper (جنگ ستارگان)، صدای Rocket (نگهبانان کهکشان) تولید کند.',
    answer1_2: 'صداهای AI tiktok که به زودی پشتیبانی خواهند شد شامل: صدای derek، صدای trickster، صدای austin butler، صدای گزارشگر خبری، صدای adam، صدای سگ، صدای miley cyrus، صدای alexa، صدای بیگانه، صدای حیوان، صدای نوزاد، صدای کامپیوتر، صدای chipmunk، صدای echo، صدای npr.',
    answer1_3: 'علاوه بر این، ابزار تولید صدای tiktok از چندین زبان دیگر پشتیبانی می‌کند، از جمله چینی، ژاپنی، کره‌ای، ویتنامی، تایلندی، هندی، فارسی، روسی، آلمانی، فرانسوی، رومانیایی، چک، اسپانیایی، پرتغالی، بنگالی، ایتالیایی، عربی، اردو، چینی سنتی، و مالایی.',
    answer1_4: 'اگر به یک صدای خاص به طور فوری نیاز دارید، لطفا به من ایمیل بزنید.',

quest2: 'مزایای تولید کننده صدای TikTok چیست؟',
    answer2_1: '- ابزار تولید کننده صدای TikTok می‌تواند انواع مختلفی از صداها را تولید کند که اغلب در ویدیوهای TikTok استفاده می‌شوند.',
    answer2_2: '- این آخرین تولید کننده صدای AI است که می‌تواند صدای tiktok tts شبیه انسان تولید کند.',
    answer2_3: '- برای ویرایش ویدیو روی رایانه شخصی راحت‌تر است.',
    answer2_4: '- شما می‌توانید از صداهایی استفاده کنید که در حال حاضر در tiktok APP tts یافت نمی‌شوند.',

quest3: 'چگونه از تولید کننده صدای TikTok استفاده کنیم؟',
    answer3_1: 'تولید کننده صدای TikTok بسیار آسان است:',
    answer3_2: '- زبان و لهجه صدا را انتخاب کنید.',
    answer3_3: '- متن را در جعبه ورودی تایپ کنید که به گفتار تبدیل شود.',
    answer3_4: '- دکمه تولید را فشار دهید و چند ثانیه صبر کنید.',
    answer3_5: '- صدای AI tiktok را پخش کنید یا دانلود کنید.',

quest4: 'معروف‌ترین و خنده‌دارترین صداهای tiktok چیستند؟',
    answer4_1: 'معروف‌ترین صدای مردانه tiktok صدای عمیق است که به طور رسمی به عنوان داستان‌گو شناخته می‌شود.',
    answer4_2: 'برای خنده‌دارترین صدای tiktok، من شخصاً صدای ghostface و صدای C3PO را توصیه می‌کنم. اینها اغلب برای دوبله ویدیوهای خنده‌دار TikTok استفاده می‌شوند زیرا به راحتی قابل تشخیص هستند و می‌توانند به سرعت بینندگان را جذب کنند.',

quest5: 'کدام صداهای TikTok آزاردهنده‌ترین هستند؟',
    answer5_1: 'من معتقدم که واقعاً هیچ صدای tiktok "آزاردهنده‌ای" وجود ندارد.',
    answer5_2: 'بیشتر به زمینه و محتوایی که صدا در آن استفاده می‌شود مربوط می‌شود که ممکن است برخی از افراد را ناراحت کند. برای مثال، برخی افراد ممکن است صدای jessie را آزاردهنده بدانند زیرا در یک دوره خاص خیلی زیاد در TikTok ظاهر شده است. با این حال، به دلیل تن صدا و کیفیت آن، این واقعاً یک اثر صدای tiktok فوق العاده است. علاوه بر این، بازیگر صدای tiktok که پشت آن است بسیار حرفه‌ای است و خودش هم ویدیوهای خود را در TikTok پست می‌کند.',
    answer5_3: 'بنابراین، من به همه پیشنهاد می‌کنم که نگرانی‌ها و پیش‌داوری‌ها را کنار بگذارند، صداها را از این ابزار چندین بار بشنوند و با صبر و حوصله بهترین اثر صدای tiktok را که برای شما مناسب است پیدا کنند.',

quest6: 'چگونه با استفاده از تولید کننده صدای TikTok صدای jessie تولید کنیم؟',
    answer6_1: '- در منوی کشویی اول ابزار تولید کننده صدای TikTok، English (US) را انتخاب کنید، سپس در منوی کشویی دیگر، صدای Jessie (صدای زن) را انتخاب کنید.',
    answer6_2: '- متن را در جعبه ورودی تایپ کنید و دکمه تولید را کلیک کنید.',
    answer6_3: '- پس از چند ثانیه یا حداکثر ده ثانیه انتظار، صدای AI را خواهید شنید. در نوار عملیات، دکمه‌ای با یک فلش رو به پایین پیدا کنید و برای دانلود کلیک کنید.',

quest7: 'چگونه با استفاده از تولید کننده صدای TikTok صدای C3PO تولید کنیم؟',
    answer7_1: '- در منوی کشویی اول ابزار تولید کننده صدای TikTok، English (US) را انتخاب کنید، سپس در منوی کشویی دیگر، صدای C3PO (صدای ربات) را انتخاب کنید.',
    answer7_2: '- متن را در جعبه ورودی تایپ کنید و دکمه تولید را کلیک کنید.',
    answer7_3: '- پس از چند ثانیه یا حداکثر ده ثانیه انتظار، صدای AI را خواهید شنید. در نوار عملیات، دکمه‌ای با یک فلش رو به پایین پیدا کنید و برای دانلود کلیک کنید.',

quest8: 'چگونه با استفاده از تولید کننده صدای TikTok صدای ghostface تولید کنیم؟',
    answer8_1: '- در منوی کشویی اول ابزار تولید کننده صدای TikTok، English (US) را انتخاب کنید، سپس در منوی کشویی دیگر، صدای ghostface را انتخاب کنید.',
    answer8_2: '- متن را در جعبه ورودی تایپ کنید و دکمه تولید را کلیک کنید.',
    answer8_3: '- پس از چند ثانیه یا حداکثر ده ثانیه انتظار، صدای AI را خواهید شنید. در نوار عملیات، دکمه‌ای با یک فلش رو به پایین پیدا کنید و برای دانلود کلیک کنید.',

quest9: 'چگونه با استفاده از تولید کننده صدای TikTok صدای siri تولید کنیم؟',
    answer9_1: '- در منوی کشویی اول ابزار تولید کننده صدای TikTok، English (US) را انتخاب کنید، سپس در منوی کشویی دیگر، صدای siri را انتخاب کنید.',
    answer9_2: '- متن را در جعبه ورودی تایپ کنید و دکمه تولید را کلیک کنید.',
    answer9_3: '- پس از چند ثانیه یا حداکثر ده ثانیه انتظار، صدای AI را خواهید شنید. در نوار عملیات، دکمه‌ای با یک فلش رو به پایین پیدا کنید و برای دانلود کلیک کنید.',

quest10: 'چگونه اثر صدای tiktok را به ویدیوهای TikTok اضافه کنیم؟',
    answer10_1: 'اگر می‌خواهید بدانید چگونه از صداهای رسمی tiktok در TikTok استفاده کنید، به زودی یک پست وبلاگ اختصاصی در این موضوع خواهم نوشت.',
    answer10_2: 'اینجا، من توضیح خواهم داد که چگونه صدای خود را به TikTok پس از تولید و دانلود از تولید کننده صدای TikTok منتشر کنید.',
    answer10_3: '1. اگر صدای tiktok را روی رایانه خود تولید کرده‌اید و می‌خواهید آن را به TikTok یا هر برنامه ویرایش ویدیو دیگری روی تلفن خود آپلود کنید، باید فایل صدا را به تلفن خود انتقال دهید. برای iPhones، می‌توانید از airDrop برای انتقال استفاده کنید. برای تلفن‌های Android، من با این فرایند آشنا نیستم، اما مطمئناً روش‌ها و ابزارهایی برای انجام این کار پیدا خواهید کرد.',
    answer10_4: '2. پس از اینکه فایل صدا به تلفن شما انتقال یافت، می‌توانید TikTok را باز کنید:',
    answer10_5: '- روی دکمه "+" در پایین رابط کلیک کنید، ویدیو را در تلفن خود انتخاب کنید.',
    answer10_6: '- پس از آپلود ویدیو، یک آیکون مربع در سمت راست صفحه پیدا کنید و به صفحه ویرایش وارد شوید.',
    answer10_7: '- در پایین صفحه، دکمه "افزودن صدا" را پیدا کنید و برای انتخاب فایل صدای تازه منتقل شده کلیک کنید.',
    answer10_8: '- در این رابط، می‌توانید برخی تنظیمات ویدیو را انجام دهید، و سپس برای انتشار آن روی دکمه بالا راست کلیک کنید.',

quest11: 'آیا TikTok Voice رایگان است؟',
    answer11: 'بله، این یک ابزار تولید صدای AI tiktok رایگان از متن به گفتار است.',

quest12: 'کجا می‌توانم صدای محبوب TikTok را در ویدیو TikTok خود تولید کنم؟',
    answer12: 'می‌توانید به https://tiktokvoice.net/ مراجعه کنید تا صدای AI tiktok را به ویدیو خود اضافه کنید.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}